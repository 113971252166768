<template>
  <v-container>
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("employee_ot.title") }}</h1>
      </div>
      <!-- <v-breadcrumbs-item
        class="breadcrumbs-hover"
        v-if="$route.name === 'employee_ot.edit'"
      >
        <span v-show="true">/</span>
        <span>{{ $t("employee_ot.edit") }}</span>
      </v-breadcrumbs-item> -->
    </div>
    <hr />
    <br />

    <v-row class="mb-4">
      <v-col cols="3" md="1" sm="1" lg="1">
        <div class="btn-new-create-action mt-2">
          <v-btn class="mt-5 btn-create lfont btn" color="info" @click="onCreate">
            {{ $t("employee_ot.create") }}</v-btn>
        </div>
      </v-col>
      <v-col cols="9" sm="6" md="6" lg="6">
        <label class="label-input">{{ $t("employee_ot.date") }}</label>
        <DatePicker class="date-time" style="width: 100%" type="date" v-model="date" valueType="format" slot="activator"
          :append-to-body="true" name="founding_date" @change="searchFilterItem()"></DatePicker>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4" sm="12" lg="4" class="mb-4" v-for="(item, idx) in listEmployeeOt" :key="idx">
        <v-card class="mx-auto">
          <v-card-text>
            <v-row class="lfont">
              <v-col cols="12" sm="12" md="12" class="mb-3 pt-3">
                <div class="d-flex justify-space-between">
                  <span>{{ $t("employee_ot.table.date") }}</span>
                  <span>{{ item.date }}</span>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-3">
                <div class="d-flex justify-space-between">
                  <span>{{ $t("employee_ot.table.emp_run_time") }}</span>
                  <span>{{ item.check_in }} - {{ item.check_out }}</span>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-3">
                <div class="d-flex justify-space-between">
                  <span>{{ $t("employee_ot.table.qty_hour") }}</span>
                  <span>
                    <span v-if="item.qty.hour > 0">
                      {{ item.qty.hour }} {{ $t("employee_ot.table.hour") }}
                    </span>

                    <span v-if="item.qty.minute > 0">
                      {{ item.qty.minute }}
                      {{ $t("employee_ot.table.minute") }}
                    </span>
                  </span>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-3">
                <div class="d-flex justify-space-between">
                  <span>{{ $t("employee_ot.table.status") }}</span>
                  <span>
                    <span v-if="item.ot_status === 'pending'" style="color: orange;">{{ item.ot_status }}</span>
                    <span v-else-if="item.ot_status === 'approve'" style="color: green;">{{ item.ot_status }}</span>
                    <span v-else style="color: red;">{{ item.ot_status }}</span>
                  </span>
                </div>
              </v-col>
              <v-col cols="12" md="12" lg="12" xs="12" sm="12" class="mt-3 mb-3">
                <v-stepper>
                  <v-stepper-header class="my-stepper">
                    <span>
                      <v-stepper-step complete color="success" step="1">
                        R
                      </v-stepper-step>
                    </span>

                    <v-divider></v-divider>

                    <span v-if="item.status == 'approve'">
                      <v-stepper-step complete color="success" step="2">
                        LM
                      </v-stepper-step>
                    </span>
                    <span v-else-if="item.status == 'reject'">
                      <v-stepper-step color="red" editable step="2" :rules="[() => false]">
                        LM
                      </v-stepper-step>
                    </span>
                    <span v-else>
                      <v-stepper-step step="2">
                        LM
                      </v-stepper-step>
                    </span>

                    <v-divider></v-divider>

                    <span v-if="item.ot_status == 'approve'">
                      <v-stepper-step complete color="success" step="3">
                        HR
                      </v-stepper-step>
                    </span>

                    <span v-else-if="item.hr_approve_status == 'reject'">
                      <v-stepper-step step="3" color="red" editable :rules="[() => false]">
                        HR
                      </v-stepper-step>
                    </span>

                    <span v-else>
                      <v-stepper-step step="3">
                        HR
                      </v-stepper-step>
                    </span>
                  </v-stepper-header>
                </v-stepper>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-actions>
            <span v-if="item.status == 'pending'">
              <v-btn text color="success accent-4" @click="onEdit(item.id)">
                Edit
              </v-btn>
              <v-btn text color="error accent-4" @click="onDeleted(item.id)">
                Delete
              </v-btn>
            </span>
            <span v-else>
              <v-btn disabled text color="success accent-4" @click="onEdit(item.id)">
                Edit
              </v-btn>
              <v-btn text disabled color="error accent-4" @click="onDeleted(item.id)">
                Delete
              </v-btn>
            </span>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- <defaultTableNoResult v-else /> -->
    <ModalDelete fullscreen title="Edit Employee Leave">
      <template v-slot="{ close }">
        <Delete :employee_ot_request_id="employee_ot_request_id" @close="close" @success="fetchEmployeeOt()" />
      </template>
    </ModalDelete>
    <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset" @paginate="fetchEmployeeOt">
    </Pagination>
    <Loading v-if="isLoading" />
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Pagination from "@/components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import Loading from "@/components/Loading";
import Delete from "./Delete";

export default {
  components: {
    DatePicker,
    Pagination,
    defaultTableNoResult,
    Loading,
    Delete,
  },
  data() {
    return {
      offset: 10,
      pagination: {},
      employeeOt: {},
      per_page: 10,
      isLoading: true,
      listEmployeeOt: [],
      searchItem: "",
      employee_ot_request_id: "",
      isLoading: true,
      date: null,
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchEmployeeOt();
      this.pagination.current_page = 1;
    },
    onCreate() {
      this.$router
        .push({
          name: "employee_ot_request.create",
        })
        .catch(() => { });
    },
    onEdit(item) {
      this.$router.push({
        name: "employee_ot_request.update",
        params: {
          employee_ot_request_id: item,
        },
      });
    },
    onDeleted(employee_ot_request_id) {
      this.employee_ot_request_id = employee_ot_request_id;
      this.$store.commit("modalDelete_State", true);
    },
    fetchEmployeeOt() {
      this.isLoading = true;
      this.$axios
        .get(`employee/request/ot`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
            date: this.date,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployeeOt = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listEmployeeOt.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeOt();
  },
};
</script>

<style lang="scss" scoped>
.v-stepper__label {
  color: red !important;
}

.fsize {
  width: 20px !important;
  font-size: 10% !important;
}
</style>
